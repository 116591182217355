export const isCurrentTimeWithinSchedule = (schedule) => {
  const now = new Date();
  const currentDay = now.toLocaleString("en-US", { weekday: "long" }); // Get current day name (e.g., "Monday")
  const currentTime = now.getHours() * 60 + now.getMinutes(); // Convert current time to minutes since midnight

  // Find today's schedule
  const todaySchedule = schedule.find((entry) => entry.day === currentDay);

  if (!todaySchedule) {
    return false; // If no schedule is found for today, return false
  }

  // Convert startTime and endTime to minutes since midnight
  const [startHour, startMinute] = todaySchedule.startTime
    .replace(/[ap]m$/, "")
    .split(":")
    .map(Number);
  const startMinutes =
    startHour * 60 +
    startMinute +
    (todaySchedule.startTime.includes("pm") && startHour !== 12 ? 720 : 0);

  const [endHour, endMinute] = todaySchedule.endTime
    .replace(/[ap]m$/, "")
    .split(":")
    .map(Number);
  const endMinutes =
    endHour * 60 +
    endMinute +
    (todaySchedule.endTime.includes("pm") && endHour !== 12 ? 720 : 0);

  // Check if current time is within start and end times
  return currentTime >= startMinutes && currentTime <= endMinutes;
};
