import React, { useEffect, useState } from "react";
import "../../assets/styles/homeStyles.css";
import "../../assets/styles/generalStyles.css";
import NewsLetterComponent from "../../widgets/NewsLetterComponent";
import Footer from "../../widgets/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import HomeNavbar from "../../widgets/HomeNavBar";
import MiniFoodCards from "../../components/home/MiniFoodCards";
import { AllRestaurants, FoodCardImg, NoAddress } from "../../assets/images";
import FoodCard from "../../components/home/FoodCard";
import AddressModal from "../../components/home/AddressModal";
import CustomerAuth from "../auth/customer/CustomerAuth";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRestaurants,
  getCart,
  getCategories,
  getProductCategories,
  getRestaurantsByCategories,
  getRestaurantsProduct,
  getTrendingRestaurants,
  getWorkingTime,
} from "../../redux/actions/CustomerActions";
import mixpanel from "mixpanel-browser";
import { useMediaQuery } from "react-responsive";
import { showWarning } from "../../utils/Toast";
import { trackEvent } from "../../utils/Mixpanel";
import SmartBanner from "../../utils/SmartBannerComponent";
import { isCurrentTimeWithinSchedule } from "../../utils/isCurrentTimeWithinSchedule";

const Home = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const params = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    showAddressModal: false,
    signInModal: false,
    signUpModal: false,
    phoneVerificationModal: false,
    loading: false,
    liked: false,
    searchValue: "",
    searchedRestaurants: [],
  });
  const {
    restaurants,
    loading,
    gettingRestaurants,
    categories,
    gettingCategories,
    trending,
    gettingTrending,
    workingTime,
  } = useSelector((state) => state.customer);
  const { addressDetails } = useSelector((state) => state.search);
  const [headerTitle, setHeader] = useState("");
  let lng = params?.state?.data?.lng || addressDetails?.lng;
  let lat = params?.state?.data?.lat || addressDetails?.lat;
  // console.log(workingTime, 'djd')

  // useEffect(() => {
  //     dispatch(getAllRestaurants(1, 300, lat, lng))
  //     dispatch(getCategories())
  //     dispatch(getTrendingRestaurants(1, 30, lat, lng))
  //     dispatch(getWorkingTime())

  // },[])

  useEffect(() => {
    if (!lat || !lng) {
      // If lat and lng are not available, get the user's current location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Update lat and lng with user's current position
            lat = position.coords.latitude;
            lng = position.coords.longitude;

            // Dispatch actions with the updated lat and lng
            dispatch(getAllRestaurants(1, 300, lat, lng));
            dispatch(getCategories());
            dispatch(getTrendingRestaurants(1, 30, lat, lng));
            dispatch(getWorkingTime());
          },
          (error) => {
            console.error("Error getting location:", error);
            // Handle location error (e.g., prompt user to enable location or use a fallback)
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        // Handle case where Geolocation is not supported
      }
    } else {
      // If lat and lng are already available, dispatch actions as usual
      dispatch(getAllRestaurants(1, 300, lat, lng));
      dispatch(getCategories());
      dispatch(getTrendingRestaurants(1, 30, lat, lng));
      dispatch(getWorkingTime());
    }
  }, []);

  const fetchLocationRestaurants = () => {
    dispatch(getAllRestaurants(1, 300, lat, lng));
    dispatch(getCategories());
    dispatch(getTrendingRestaurants(1, 30, lat, lng));
  };

  const hideScroll = () => {
    document.body.style.overflow = "hidden";
  };
  const unhideScroll = () => {
    document.body.style.overflow = "unset";
  };

  const openAddressModal = () => {
    setState((prevState) => ({ ...prevState, showAddressModal: true }));
    hideScroll();
  };
  const closeAddressModal = () => {
    setState((prevState) => ({ ...prevState, showAddressModal: false }));
    unhideScroll();
  };

  const openSignInModal = () => {
    setState((prevState) => ({ ...prevState, signInModal: true }));
    closePhoneVerificationModal();
    closeSignUpModal();
    hideScroll();
  };
  const closeSignInModal = () => {
    setState((prevState) => ({ ...prevState, signInModal: false }));
    unhideScroll();
  };

  const openSignUpModal = () => {
    setState((prevState) => ({ ...prevState, signUpModal: true }));
    closePhoneVerificationModal();
    closeSignInModal();
    hideScroll();
  };
  const closeSignUpModal = () => {
    setState((prevState) => ({ ...prevState, signUpModal: false }));
    unhideScroll();
  };

  const openPhoneVerificationModal = () => {
    setState((prevState) => ({ ...prevState, phoneVerificationModal: true }));
    closeSignInModal();
    closeSignUpModal();
    hideScroll();
  };
  const closePhoneVerificationModal = () => {
    setState((prevState) => ({ ...prevState, phoneVerificationModal: false }));
    unhideScroll();
  };

  const dispatch = useDispatch();

  const getRestaurantsByCat = (categories) => {
    const page = 1;
    const limit = 10;
    dispatch(getRestaurantsByCategories(page, limit, lat, lng, categories?.id));
    setHeader(categories?.name);
  };



  const goToRestaurantsDetails = (data) => {
    

    if (isCurrentTimeWithinSchedule(data.businessHours)) {
      navigate(`/restaurants/${data.id}`, { state: { data: data } });

      trackEvent("restaurant-clicked", { restaurant: data });

      dispatch(getRestaurantsProduct(data?.id, 0));
      dispatch(getCart());
      dispatch(getProductCategories(data?.id));
    } else {
      showWarning("Stores closed");
    }
  };

  const renderEmptySearch = () => {
    if (state.searchedRestaurants?.length === 0 && state.searchValue !== "") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <img src={NoAddress} alt={"poster"} />
            <h3
              className={"boldText f22 textCenter"}
              style={{ color: "#B1B2B4", marginTop: "20px" }}
            >
              No Restaurants Found
            </h3>
          </div>
        </div>
      );
    }
  };

  const onChangeSearch = (e) => {
    let arr = restaurants?.data?.businesses;
    const searched = arr?.filter((x) =>
      x?.businessName.toLowerCase().includes(e.target.value?.toLowerCase())
    );
    setState((prevState) => ({
      ...prevState,
      searchValue: e.target.value,
      searchedRestaurants: searched,
    }));
  };

  const returnAllRestaurants = () => {
    dispatch(getAllRestaurants(1, 30, lat, lng));
    setHeader("All Restaurants");
  };

  const renderAllRestaurants = () => {
    let allRestaurants = restaurants?.data?.businesses;
    if (gettingRestaurants || loading) {
      return (
        <div className={"allRestaurantsGrid"}>
          <FoodCard
            img={FoodCardImg}
            title={"Polo park mall Court"}
            price={"2000"}
            rating={"3.5"}
            width={"100%"}
            height={"auto"}
            gettingRestaurants={gettingRestaurants}
            loading={loading}
          />
          <FoodCard
            img={FoodCardImg}
            title={"Polo park mall Court"}
            price={"2000"}
            rating={"3.5"}
            width={"100%"}
            height={"auto"}
            gettingRestaurants={gettingRestaurants}
            loading={loading}
          />
          <FoodCard
            img={FoodCardImg}
            title={"Polo park mall Court"}
            price={"2000"}
            rating={"3.5"}
            width={"100%"}
            height={"auto"}
            gettingRestaurants={gettingRestaurants}
            loading={loading}
          />
        </div>
      );
    }

    if (allRestaurants && allRestaurants?.length !== 0) {
      if (state.searchValue) {
        allRestaurants = state.searchedRestaurants;
      }
      return allRestaurants?.map((item, index) => {
        return (
          <FoodCard
            data={item}
            width={"100%"}
            onClick={() => goToRestaurantsDetails(item)}
            imageHeight={"200px"}
            index={index}
          />
        );
      });
    }

    if (
      restaurants?.data?.businesses &&
      restaurants?.data?.businesses?.length === 0
    ) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <img src={NoAddress} alt={"poster"} />
            <h3
              className={"boldText f22 textCenter"}
              style={{ color: "#B1B2B4", marginTop: "20px" }}
            >
              No Restaurants Available
            </h3>
          </div>
        </div>
      );
    }

    if (allRestaurants && allRestaurants?.length === 0) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <img src={NoAddress} alt={"poster"} />
            <h3
              className={"boldText f22 textCenter"}
              style={{ color: "#B1B2B4", marginTop: "20px" }}
            >
              No Restaurants Available
            </h3>
          </div>
        </div>
      );
    }
  };

  const renderCategories = () => {
    if (gettingCategories) {
      return (
        <MiniFoodCards
          // img={Burger}
          title={"Home chef"}
          subTitle={"12 places"}
          loading={gettingCategories}
        />
      );
    }
    if (categories && categories?.data?.categories?.length !== 0) {
      return categories?.data?.categories?.map((item, index) => {
        return (
          <div key={index}>
            <MiniFoodCards
              img={item?.Image}
              title={item.name}
              subTitle={item?.businessCount}
              onClick={() => getRestaurantsByCat(item)}
              index={index}
            />
          </div>
        );
      });
    }
    if (categories && categories?.data?.categories?.length === 0) {
      return (
        <h3 className={"boldText f22"} style={{ color: "#B1B2B4" }}>
          No Categories Available
        </h3>
      );
    }
  };

  const renderListByCategories = () => {
    if (gettingTrending) {
      return (
        <div>
          <FoodCard
            width={"300px"}
            height={"250px"}
            gettingRestaurants={gettingTrending}
          />
        </div>
      );
    }
    if (trending && trending?.data?.businesses?.length !== 0) {
      return trending?.data?.businesses?.map((item, index) => {
        return (
          <div>
            {isPortrait && isTabletOrMobile ? (
              <FoodCard
                data={item}
                onClick={() => goToRestaurantsDetails(item)}
                customStyle={"categoriesFoodCard"}
                height={"200px"}
                key={index}
              />
            ) : (
              <FoodCard
                data={item}
                onClick={() => goToRestaurantsDetails(item)}
                customStyle={"categoriesFoodCard"}
                key={index}
              />
            )}
          </div>
        );
      });
    }
    if (trending && trending?.data?.businesses?.length === 0) {
      return (
        <h3 className={"boldText f22"} style={{ color: "#B1B2B4" }}>
          No Restaurants Categories Available
        </h3>
      );
    }
  };

  const customerIosStore = () => {
    window.open(
      "https://apps.apple.com/us/app/dado-food/id6444361467",
      "_blank",
      "noreferrer"
    );
    trackEvent("clicks download app", {
      appType: "customer-app",
      version: "ios",
      location: "footer-section",
    });
  };
  const customerPlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.dado.ng",
      "_blank",
      "noreferrer"
    );
    trackEvent("clicks download app", {
      appType: "customer-app",
      version: "android",
      location: "footer-section",
    });
  };

  return (
    <>
      <SmartBanner />
      <HomeNavbar
        bgColor={"#ffffff"}
        onClick={openAddressModal}
        openAuthModal={openSignInModal}
        onChange={onChangeSearch}
        placeHolder={"Search for restaurants"}
        value={state.searchValue}
        showSearch={true}
      />
      <main className={"landingPageContainer customerLandingPage"}>
        <section className={"homeHeroSection"}>
          <div>
            <h1 className={"boldText"}>
              Order your meals from restaurants around you!
            </h1>
            <p className={"regularText"}>
              Explore our variety of vendors and meals
            </p>
          </div>
          <div style={{ marginTop: "40px" }}>
            {/* <p className={'regularText f14'} style={{ fontSize: '18px', maxWidth: '600px' }}>
                            Experience Dado on the go! Download our mobile app for a faster, smoother food delivery experience from your favorite local restaurants.
                        </p> */}
            <div className={"flexRow alignCenter"} style={{ gap: "10px" }}>
              <button onClick={customerPlayStore}>
                <img
                  src={
                    "https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340589868"
                  }
                  alt="appStore"
                />
              </button>
              <button onClick={customerIosStore}>
                <img
                  src={
                    "https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340617282"
                  }
                  alt="appStore"
                />
              </button>
            </div>
          </div>
        </section>
        <section className={"categoriesSection"}>
          <h3 className={"boldText"}>Explore our top categories</h3>
          <div className={"categoriesGrid"}>
            {categories?.data?.categories?.length !== 0 &&
              !gettingCategories && (
                <div>
                  <MiniFoodCards
                    img={AllRestaurants}
                    title={"All restaurants"}
                    onClick={returnAllRestaurants}
                  />
                </div>
              )}
            {renderCategories()}
          </div>
        </section>
        <section className={"categoriesCarousel"}>
          <div>
            <h4 className={"boldText"}>🔥️ Hot on Dado</h4>
            <div className={"categoriesGrid carouselFlex"} id="leftAlign">
              {renderListByCategories()}
            </div>
          </div>
        </section>

        <section className={"categoriesCarousel allRestaurantsContainer"}>
          {headerTitle ? (
            <h4 className={"boldText"}>{headerTitle}</h4>
          ) : (
            <h4 className={"boldText"}>All restaurants</h4>
          )}
          <div
            className={"allRestaurantsGrid"}
            style={{ marginBottom: "56px" }}
          >
            {renderAllRestaurants()}
            {renderEmptySearch()}
          </div>
        </section>
        <Footer />

        <AddressModal
          visible={state.showAddressModal}
          onDecline={closeAddressModal}
          fetchLocationRestaurants={fetchLocationRestaurants}
        />
        <CustomerAuth
          signIn={state.signInModal}
          signUp={state.signUpModal}
          phoneVerification={state.phoneVerificationModal}
          closePhoneVerification={closePhoneVerificationModal}
          closeSignIn={closeSignInModal}
          closeSignUp={closeSignUpModal}
          openPhoneVerificationModal={openPhoneVerificationModal}
          openSignInModal={openSignInModal}
          openSignUpModal={openSignUpModal}
        />
      </main>
    </>
  );
};

export default Home;
